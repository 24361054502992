module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Verhuisdeal","short_name":"Verhuisdeal","start_url":"/","background_color":"#195078","theme_color":"#EE751F","display":"standalone","icon":"src/images/logo/verhuisdeal-24-checkmark.svg"},
    },{
      plugin: require('../node_modules/gatsby-plugin-layout/gatsby-browser.js'),
      options: {"plugins":[],"component":"/Users/bob/Sites/merter/verhuisdeal24/frontend/src/components/layout.js"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
