// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-js": () => import("./../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-index-js": () => import("./../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-offerte-js": () => import("./../src/pages/offerte.js" /* webpackChunkName: "component---src-pages-offerte-js" */),
  "component---src-templates-city-template-js": () => import("./../src/templates/cityTemplate.js" /* webpackChunkName: "component---src-templates-city-template-js" */),
  "component---src-templates-country-template-js": () => import("./../src/templates/countryTemplate.js" /* webpackChunkName: "component---src-templates-country-template-js" */),
  "component---src-templates-offer-template-js": () => import("./../src/templates/offerTemplate.js" /* webpackChunkName: "component---src-templates-offer-template-js" */),
  "component---src-templates-service-template-js": () => import("./../src/templates/serviceTemplate.js" /* webpackChunkName: "component---src-templates-service-template-js" */)
}

