// Icon
import React from "react"

function Icon({ className }) {
  return (
    <svg
      fill="none"
      height="24"
      viewBox="0 0 24 24"
      width="24"
      className={className}
    >
      <clipPath id="a">
        <path d="m0 0h24v24h-24z" />
      </clipPath>
      <g clipPath="url(#a)">
        <path
          d="m22.2691 0h-20.5382c-.955998 0-1.7309.774902-1.7309 1.7309v20.5382c0 .956.774902 1.7309 1.7309 1.7309h20.5382c.956 0 1.7309-.7749 1.7309-1.7309v-20.5382c0-.955998-.7749-1.7309-1.7309-1.7309zm-13.75616 18.1406h-2.92255v-8.79252h2.92255zm-1.46118-9.99314h-.01905c-.98071 0-1.61499-.67511-1.61499-1.51886 0-.86279.65369-1.51922 1.65345-1.51922.99975 0 1.61499.65643 1.63403 1.51922 0 .84375-.63428 1.51886-1.65344 1.51886zm11.99924 9.99314h-2.9222v-4.7038c0-1.1821-.4231-1.9883-1.4805-1.9883-.8074 0-1.2882.5438-1.4995 1.0688-.0773.1878-.0961.4504-.0961.7132v4.9101h-2.9224s.0383-7.9676 0-8.79252h2.9224v1.24492c.3883-.5991 1.0832-1.45128 2.6338-1.45128 1.9228 0 3.3645 1.25668 3.3645 3.95728z"
          fill="#000"
        />
      </g>
    </svg>
  )
}

export default Icon
