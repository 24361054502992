import data from "../data/verhuizen-m3.json"

const m3Price = 1

export const ecommerceValues = values => {
  const events = {}
  const products = data.data.m3.reduce((combined, category) => {
    const objects = category.objects.reduce((acc, val) => {
      const price = val.m3 * m3Price
      const m3 = val.m3
      const object = {
        [`${category.categoryName}.${val.objectName}`]: {
          sku: `${category.categoryName}.${val.objectName}`,
          name: val.objectTitle,
          category: category.categoryTitle,
          price: parseFloat(price).toFixed(2),
          quantity: 1,
          m3: parseFloat(m3).toFixed(2),
        },
      }
      return { ...acc, ...object }
    }, {})
    return { ...combined, ...objects }
  }, {})

  Object.entries(values).forEach(([category, objects]) => {
    Object.entries(objects).forEach(([object, quantity]) => {
      const key = `${category}.${object}`
      try {
        const product = products[key]
        if (!events[product.category]) {
          events[product.category] = {
            category: product.category,
            objects: [],
          }
        }
        events[product.category].objects.push({
          ...product,
          price: product.price * quantity,
          quantity,
        })
      } catch (er) {
        console.log("key not found", products, key, er)
      }
    })
  })

  return events
}

export const virtualPageEvent = props => {
  try {
    window.dataLayer = window.dataLayer || []
    window.dataLayer.push({
      event: "formSubmission",
      formName: "Offerte",
      ...props,
    })
  } catch (e) {
    console.log("There is no dataLayer defined: ", e)
  }
}

export const ecommerceEvent = (id, values) => {
  if (!values || values.length <= 0) {
    return
  }
  let events = []
  Object.entries(values).forEach(([category, value]) => {
    events = [...events, ...value.objects]
  })
  try {
    const transactionTotal = events.reduce(
      (total, product) => total + product.price,
      0
    )
    window.dataLayer = window.dataLayer || []
    window.dataLayer.push({
      event: "transaction",
      transactionId: id,
      transactionTotal,
      transactionProducts: events,
    })
  } catch (e) {
    console.log("There is no dataLayer defined: ", e)
  }
}
