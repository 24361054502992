// Icon
import React from "react"

function Icon({ className }) {
  return (
    <svg
      fill="none"
      height="24"
      viewBox="0 0 24 24"
      width="24"
      className={className}
    >
      <clipPath id="a">
        <path d="m0 0h24v24h-24z" />
      </clipPath>
      <g clipPath="url(#a)" fill="#000">
        <path d="m12.2567 5.83447c-3.29277 0-5.97069 2.67682-5.97215 5.96723-.00037 1.1276.3153 2.2256.91278 3.1758l.14191.2257-.60316 2.2023 2.25935-.5926.21807.1293c.9164.5438 1.9671.8315 3.0383.8318h.0024c3.2902 0 5.9681-2.677 5.9696-5.9675.0005-1.5947-.6198-3.09398-1.7469-4.22191-1.127-1.12792-2.6259-1.74957-4.2202-1.75012zm3.5113 8.53293c-.1496.4189-.8665.8014-1.2113.8531-.3093.0461-.7004.0653-1.1303-.0711-.2607-.0827-.5949-.1932-1.0232-.3779-1.8001-.7771-2.97583-2.5891-3.06555-2.7089-.08972-.1197-.73279-.9728-.73279-1.8559 0-.88297.46363-1.31711.62805-1.49656.16462-.17962.35907-.22448.47864-.22448s.23932.0011.34385.00622c.1103.0055.2582-.04193.4038.30799.1496.35925.5085 1.24223.5533 1.33193.0449.0899.0747.1946.015.3144-.0598.1197-.3422.4804-.4486.6136-.0793.0988-.1834.1865-.0787.3662.1045.1794.4649.767.9983 1.2427.6855.6112 1.2638.8005 1.4432.8904.1793.0898.284.0748.3888-.0448.1045-.1198.4486-.5239.5681-.7035.1196-.1796.2393-.1496.4038-.0897.1646.0597 1.0468.4938 1.2262.5835.1795.0899.299.1348.3439.2097.0451.0749.0451.4339-.1045.8531z" />
        <path d="m22.2691 0h-20.5382c-.955998 0-1.7309.774902-1.7309 1.7309v20.5382c0 .956.774902 1.7309 1.7309 1.7309h20.5382c.956 0 1.7309-.7749 1.7309-1.7309v-20.5382c0-.955998-.7749-1.7309-1.7309-1.7309zm-10.0148 18.9868c-.0001 0 .0002 0 0 0h-.0029c-1.2021-.0006-2.3833-.302-3.43231-.874l-3.80731.9985 1.01898-3.7207c-.6286-1.0888-.95929-2.324-.95874-3.5895.00165-3.95869 3.22357-7.17933 7.18228-7.17933 1.9212.00073 3.7246.74853 5.0805 2.10552 1.3561 1.35718 2.1024 3.16114 2.1017 5.07951-.0017 3.9589-3.224 7.18-7.1822 7.18z" />
      </g>
    </svg>
  )
}

export default Icon
