/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react"
import PropTypes from "prop-types"

import { Provider } from "react-redux"
import { createStore, applyMiddleware } from "redux"
import styled from "@emotion/styled"
import thunk from "redux-thunk"
import reducer from "../store/reducer"

import Header from "./header"
import StrippedHeader from "./header-with-logo"
import Main from "./main"
import Footer from "./footer"

const store = createStore(reducer, applyMiddleware(thunk))

const LayoutContainer = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100vh;
`

const Layout = ({ children, pageContext }) => {
  const extracted =
    pageContext && pageContext.matchPath
      ? pageContext.matchPath.match(/^\/([^?/]+)/)
      : []

  if (extracted.length >= 2 && extracted[1] === "offerte") {
    return (
      <Provider store={store}>
        <LayoutContainer>
          <StrippedHeader></StrippedHeader>
          <Main>{children}</Main>
        </LayoutContainer>
      </Provider>
    )
  }

  return (
    <Provider store={store}>
      <LayoutContainer>
        <Header></Header>
        <Main>{children}</Main>
        <Footer></Footer>
      </LayoutContainer>
    </Provider>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
