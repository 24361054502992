// Icon
import React from "react"

function Icon({ className }) {
  return (
    <svg
      fill="none"
      height="24"
      viewBox="0 0 24 24"
      width="24"
      className={className}
    >
      <path
        clipRule="evenodd"
        d="m22.2691 0h-20.5382c-.955998 0-1.7309.774902-1.7309 1.7309v20.5382c0 .956.774902 1.7309 1.7309 1.7309h20.5382c.956 0 1.7309-.7749 1.7309-1.7309v-20.5382c0-.955998-.7749-1.7309-1.7309-1.7309zm-12.76997 15.5966c.05116.0341.11084.0682.17052.1023h.00002c.39223.2217.81003.3496 1.27053.3411.5799 0 1.0488-.145 1.5093-.4946.1023-.0767.2132-.1705.3155-.2728.2184-.2117.4372-.4234.6561-.6353l.001-.001.0002-.0001c.8916-.8629 1.7854-1.7279 2.6683-2.6039 1.0573-1.0489 1.0659-2.47289.0085-3.5132-.6961-.68149-1.3953-1.36143-2.0949-2.04181l-.0002-.00023-.0002-.00013c-.9372-.91145-1.8753-1.8237-2.8078-2.74153-.2132-.20465-.3155-.17907-.486.03411-.7163.93798-.6396 2.24263.2217 3.11239 1.0403 1.03178 2.0806 2.05503 3.1465 3.0527.2814.2643.2814.4093 0 .6736-.835.7983-1.66 1.6039-2.4845 2.409l-.0002.0002c-.708.6913-1.4155 1.3822-2.12848 2.0676-.23876.2302-.21318.3496.03411.5116zm4.32897 1.3586c.8311.0035 1.6641.007 2.4928-.0113.4007-.0085.4519.145.3837.4861-.1876 1.0062-1.1512 1.9015-2.2171 1.9186-1.53.0217-3.0636.0192-4.59836.0167-.87726-.0014-1.75492-.0028-2.63259.0003-.28786 0-.26829-.1425-.24365-.3219.00164-.0119.0033-.024.00489-.0362.17054-1.1171 1.15116-2.0124 2.28526-2.038.84055-.0183 1.68105-.0148 2.52165-.0113.3362.0014.6724.0028 1.0086.0028v-.0086c.331 0 .6628.0014.9948.0028z"
        fill="#000"
        fillRule="evenodd"
      />
    </svg>
  )
}

export default Icon
