import PropTypes from "prop-types"
import React from "react"
import styled from "@emotion/styled"

const MainContainer = styled.main`
  flex: 1 1 auto;
`

function Main({ children }) {
  return <MainContainer>{children}</MainContainer>
}
Main.propTypes = {}

Main.defaultProps = {
  children: PropTypes.array.isRequired,
}

export default Main
