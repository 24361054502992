import React from "react"
import { Link } from "gatsby"
import logo from "../images/logo/verhuisdeal-24-logo.svg"

const Header = () => {
  return (
    <>
      <div className="bg-white">
        <div className="container-fluid">
          <div className="row">
            <div className="col-12">
              <nav className="navbar navbar-expand-lg navbar-light">
                <Link className="navbar-brand" to="/" aria-label="Home">
                  <img className="brand__logo" src={logo} alt="Logo" />
                </Link>
              </nav>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default Header
