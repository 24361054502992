/* eslint-disable import/prefer-default-export */

// eslint-disable-next-line func-names
export const replacer2 = function(key, value) {
  if (this[key] instanceof Date) {
    const date = this[key]
    const newDate = new Date(
      date.getTime() - date.getTimezoneOffset() * 60000
    ).toJSON()
    return newDate
  }
  return value
}

// eslint-disable-next-line func-names
export const replacer = function(key, value) {
  if (this[key] instanceof Date) {
    return this[key].toUTCString()
  }

  return value
}

export const category = cat => {
  let pathPrefix

  switch (cat) {
    case "city":
      pathPrefix = "verhuizen-in"
      break
    case "country":
      pathPrefix = "verhuizen-naar"
      break
    case "service":
      pathPrefix = "diensten"
      break
    case "offer":
      pathPrefix = "tarieven"
      break
    default:
    // code block
  }

  return {
    pathPrefix,
  }
}

/*
export const useTraceUpdate = props => {
  const prev = useRef(props)
  useEffect(() => {
    const changedProps = Object.entries(props).reduce((ps, [k, v]) => {
      if (prev.current[k] !== v) {
        ps[k] = [prev.current[k], v]
      }
      return ps
    }, {})
    if (Object.keys(changedProps).length > 0) {
      console.log("Changed props:", changedProps)
    }
    prev.current = props
  })
}
*/
