import React, { useState, useRef } from "react"
import { Link } from "gatsby"
import { useSpring, animated } from "react-spring"
import useComponentSize from "@rehooks/component-size"
import classNames from "classnames/bind"
import logo from "../images/logo/verhuisdeal-24-logo.svg"
import Menu from "./menu"

const Header = () => {
  const [isOpen, setOpen] = useState(false)
  const ref = useRef(null)
  const { height } = useComponentSize(ref)

  const props = useSpring({
    height: isOpen ? height : 0,
    delay: isOpen ? 0 : 200,
  })

  const toggleOpen = () => {
    setOpen(!isOpen)
  }

  const escFunction = event => {
    if (event.keyCode === 27) {
      setOpen(false)
    }
  }

  const maskClass = classNames({
    hideMenu: !isOpen,
    showMenu: isOpen,
  })

  const togglerClass = classNames({
    "navbar-toggler hamburger hamburger--squeeze": true,
    collapsed: !isOpen,
    "is-active": isOpen,
  })

  const navbarClass = classNames({
    "navbar-collapse collapse show bg-white": true,
  })

  return (
    <>
      {isOpen && (
        <div
          className={maskClass}
          role="presentation"
          onClick={event => {
            event.preventDefault()
            setOpen(false)
            return false
          }}
          onKeyDown={event => escFunction(event)}
        />
      )}
      <div className="bg-white">
        <div className="container-fluid">
          <div className="row">
            <div className="col-12">
              <nav className="navbar navbar-expand-lg navbar-light">
                <Link className="navbar-brand" to="/" aria-label="Home">
                  <img className="brand__logo" src={logo} alt="Logo" />
                </Link>
                <button
                  className={togglerClass}
                  type="button"
                  data-toggle="collapse"
                  data-target="#navbarSupportedContent"
                  aria-controls="navbarSupportedContent"
                  aria-expanded="false"
                  aria-label="Toggle navigatie"
                  onClick={() => toggleOpen()}
                >
                  <span className="hamburger-box">
                    <span className="hamburger-inner"></span>
                  </span>
                </button>
                <div className={navbarClass} id="navbarSupportedContent">
                  <Menu isDesktop isOpen onClose={() => setOpen(false)}></Menu>
                  <animated.div
                    style={{
                      ...props,
                      overflow: "hidden",
                      position: "relative",
                    }}
                  >
                    <div className="hamburger-menu d-block d-lg-none" ref={ref}>
                      <Menu
                        isOpen={isOpen}
                        onClose={() => setOpen(false)}
                      ></Menu>
                    </div>
                  </animated.div>
                </div>
              </nav>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default Header
