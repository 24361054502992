import { set, merge } from "lodash"
import * as actionTypes from "./actions"

const initialState = {
  cta: {
    uuids: [],
  },
  form: {
    currentPage: {},
    values: {},
    initialValues: {},
  },
  houseHold: {
    objects: [],
  },
  order: {
    orders: [],
    loading: false,
    purchased: false,
  },
}

const requestOfferStart = (state, action) => {
  return {
    ...state,
    order: {
      ...state.order,
      loading: false,
      purchased: false,
    },
  }
}

const requestOfferSuccess = (state, action) => {
  // const newOrder = updateObject(action.orderData, { id: action.orderId })

  return {
    ...state,
    order: {
      ...state.order,
      loading: false,
      purchased: true,
      // orders: state.orders.concat(newOrder),
    },
  }
}

const requestOfferFail = (state, action) => {
  return {
    ...state,
    order: {
      ...state.order,
      loading: false,
      purchased: false,
    },
  }
}

export const m3 = houseHoldObjects => {
  return houseHoldObjects.reduce((total, object) => {
    const m3Object = object.count * object.m3
    const subTotal = total[object.categoryName]
      ? total[object.categoryName] + m3Object
      : m3Object
    const grandTotal = total.total ? total.total + m3Object : m3Object
    const t = {
      ...total,
      [object.categoryName]: subTotal,
      total: grandTotal,
    }
    return t
  }, {})
}

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.ADD_OBJECT: {
      const { objects } = state.houseHold
      const copy = objects.filter(
        p => p.objectName !== action.object.objectName
      )
      if (action.object.count > 0) {
        copy.push(action.object)
      }
      const houseHold = {
        ...state.houseHold,
        objects: [...copy],
      }
      const surface = m3(houseHold.objects)
      return {
        ...state,
        houseHold,
        surface,
      }
    }
    case actionTypes.CHANGE_FORMINPUT: {
      const { input } = action
      const obj = {}
      set(obj, input.name, input.value)
      const values = merge(state.form.values, obj)
      return {
        ...state,
        form: { ...state.form, values: { ...values } },
      }
    }

    case actionTypes.FETCH_INITIAL_VALUES: {
      return {
        ...state,
        form: {
          ...state.form,
          initialValues: { ...state.form.values },
        },
      }
    }

    case actionTypes.ADD_CTA: {
      const { uuids } = state.cta
      const copy = uuids.filter(uuid => uuid !== action.uuid)
      copy.push(action.uuid)
      return {
        ...state,
        cta: {
          ...state.cta,
          uuids: [...copy],
        },
      }
    }

    case actionTypes.REMOVE_CTA: {
      const { uuids } = state.cta
      const copy = uuids.filter(uuid => uuid !== action.uuid)
      return {
        ...state,
        cta: {
          ...state.cta,
          uuids: [...copy],
        },
      }
    }

    case actionTypes.REQUEST_OFFER_START:
      return requestOfferStart(state, action)
    case actionTypes.REQUEST_OFFER_SUCCESS:
      return requestOfferSuccess(state, action)
    case actionTypes.REQUEST_OFFER_FAIL:
      return requestOfferFail(state, action)

    default:
      return state
  }
}

export default reducer
