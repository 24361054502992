// Icon
import React from "react"

function Icon({ className }) {
  return (
    <svg
      fill="none"
      height="24"
      viewBox="0 0 24 24"
      width="24"
      className={className}
    >
      <path
        clipRule="evenodd"
        d="m1.7309 0h20.5382c.956 0 1.7309.774902 1.7309 1.7309v20.5382c0 .956-.7749 1.7309-1.7309 1.7309h-20.5382c-.955998 0-1.7309-.7749-1.7309-1.7309v-20.5382c0-.955998.774902-1.7309 1.7309-1.7309zm14.7372 6.61153-2.2677 1.85653c-.6381-.39883-1.3923-.62923-2.2004-.62923-1.8246 0-3.37498 1.1746-3.93652 2.80887h-.00005c-.14539.4238-.22463.8788-.22463 1.3523.00006.5138.09313 1.0061.2639 1.4603l-.00002.0001c.59114 1.5777 2.11302 2.7008 3.89732 2.7008.7669 0 1.4854-.2074 2.1019-.5693h.0001c.8185-.48 1.4581-1.2311 1.795-2.1304h-3.7431v-2.7692h3.7977 2.9261c.0804.4234.1223.8607.1223 1.3077 0 .5011-.0527.99-.153 1.4615-.3408 1.6046-1.2312 3.0058-2.4646 3.9973h-.0001l-.0003-.0004h-.0001v.0001l.0004.0003c-1.1996.9643-2.7235 1.5412-4.3823 1.5412-2.66579 0-4.98347-1.49-6.16579-3.6827h.00002c-.5323-.9873-.83423-2.1169-.83423-3.3173 0-1.1608.2823-2.25543.78269-3.21927h.00004c1.16502-2.24613 3.51192-3.78073 6.21727-3.78073 1.6985 0 3.2558.60501 4.4681 1.61153z"
        fill="#000"
        fillRule="evenodd"
      />
    </svg>
  )
}

export default Icon
