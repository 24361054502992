// Icon
import React from "react"

function Icon({ className }) {
  return (
    <svg
      fill="none"
      height="24"
      viewBox="0 0 24 24"
      width="24"
      className={className}
    >
      <clipPath id="a">
        <path d="m0 0h24v24h-24z" />
      </clipPath>
      <g clipPath="url(#a)" fill="#000">
        <path d="m17.3716 7.93622c-.1104-.2992-.2866-.57001-.5155-.7923-.2222-.22888-.4929-.40503-.7923-.51544-.2428-.0943-.6075-.20654-1.2793-.23712-.7268-.03314-.9447-.04029-2.7845-.04029-1.84 0-2.05792.00696-2.78448.0401-.67182.03077-1.03675.14301-1.27936.23731-.29938.11041-.57019.28656-.7923.51544-.22888.22229-.40503.49292-.51563.7923-.09429.2428-.20654.60773-.23712 1.27954-.03314.72656-.04028.94444-.04028 2.78444 0 1.8399.00714 2.0578.04028 2.7845.03058.6718.14283 1.0366.23712 1.2794.1106.2994.28656.57.51545.7923.22229.2289.49292.405.79229.5154.2428.0945.60773.2068 1.27955.2373.72656.0332.94428.0401 2.78428.0401 1.8402 0 2.0581-.0069 2.7845-.0401.6718-.0305 1.0367-.1428 1.2795-.2373.601-.2318 1.076-.7068 1.3078-1.3077.0943-.2428.2065-.6076.2373-1.2794.0331-.7267.0401-.9446.0401-2.7845 0-1.84-.007-2.05788-.0401-2.78444-.0306-.67181-.1428-1.03674-.2373-1.27954zm-5.3716 7.60218c-1.9543 0-3.53851-1.5841-3.53851-3.5383 0-1.9543 1.58421-3.53837 3.53851-3.53837 1.9541 0 3.5383 1.58407 3.5383 3.53837 0 1.9542-1.5842 3.5383-3.5383 3.5383zm3.6782-6.38966c-.4566 0-.8269-.37024-.8269-.8269 0-.45667.3703-.82691.8269-.82691.4567 0 .8269.37024.8269.82691-.0002.45666-.3702.8269-.8269.8269z" />
        <path d="m14.2969 12c0 1.2686-1.0283 2.2969-2.2969 2.2969s-2.29688-1.0283-2.29688-2.2969 1.02828-2.29688 2.29688-2.29688 2.2969 1.02828 2.2969 2.29688z" />
        <path d="m22.2691 0h-20.5382c-.955998 0-1.7309.774902-1.7309 1.7309v20.5382c0 .956.774902 1.7309 1.7309 1.7309h20.5382c.956 0 1.7309-.7749 1.7309-1.7309v-20.5382c0-.955998-.7749-1.7309-1.7309-1.7309zm-3.42 14.8409c-.0334.7335-.15 1.2343-.3203 1.6726-.358.9256-1.0897 1.6573-2.0153 2.0153-.4381.1703-.9391.2868-1.6724.3203-.7348.0335-.9696.0415-2.8409.0415-1.8715 0-2.1061-.008-2.84108-.0415-.73334-.0335-1.23432-.15-1.67249-.3203-.45996-.173-.87634-.4442-1.22058-.7949-.35046-.344-.62164-.7606-.79468-1.2204-.17028-.4381-.28692-.9391-.32025-1.6724-.03387-.735-.04174-.9698-.04174-2.8411s.00787-2.10608.04156-2.84088c.03333-.73352.14978-1.23432.32007-1.67267.17303-.45978.4444-.87634.79504-1.2204.34406-.35064.76062-.62182 1.2204-.79486.43835-.17029.93915-.28674 1.67267-.32025.7348-.03351.96958-.04156 2.84088-.04156s2.1061.00805 2.8409.04174c.7335.03333 1.2343.14978 1.6726.31989.4598.17303.8764.4444 1.2206.79504.3505.34424.6219.76062.7947 1.2204.1705.43835.2869.93915.3204 1.67267.0336.7348.0414.96958.0414 2.84088s-.0078 2.1061-.0415 2.8409z" />
      </g>
    </svg>
  )
}

export default Icon
