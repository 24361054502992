import axios from "../utils/axios-orders"

import { ecommerceEvent, ecommerceValues } from "../utils/events"

export const ADD_OBJECT = "ADD_OBJECT"
export const CHANGE_FORMINPUT = "CHANGE_FORMINPUT"
export const FETCH_INITIAL_VALUES = "FETCH_INITIAL_VALUES"
export const ADD_CTA = "ADD_CTA"
export const REMOVE_CTA = "REMOVE_CTA"
export const POST_OFFER = "POST_OFFER"

export const REQUEST_OFFER_START = "REQUEST_OFFER_START"
export const REQUEST_OFFER_SUCCESS = "REQUEST_OFFER_SUCCESS"
export const REQUEST_OFFER_FAIL = "REQUEST_OFFER_FAIL"

export const requestOfferSuccess = (id, orderData) => {
  ecommerceEvent(id, orderData)

  return {
    type: REQUEST_OFFER_SUCCESS,
    orderId: id,
    orderData,
  }
}

export const requestOfferFail = error => {
  return {
    type: REQUEST_OFFER_FAIL,
    error,
  }
}

export const requestOfferStart = () => {
  return {
    type: REQUEST_OFFER_START,
  }
}

export const requestOffer = orderData => {
  return dispatch => {
    dispatch(requestOfferStart())
    const transactionProducts = ecommerceValues(orderData.household)
    const submitValues = {
      ...orderData,
      rooms: { ...transactionProducts },
    }

    axios
      .post("/mail", submitValues)
      .then(response => {
        console.log(response.data)
        const id = response.data && response.data.id ? response.data.id : 0
        dispatch(requestOfferSuccess(id, orderData))
      })
      .catch(error => {
        dispatch(requestOfferFail(error))
      })
  }
}
