import React from "react"
import { Link } from "gatsby"
import classNames from "classnames/bind"
import { Keyframes, config } from "react-spring/renderprops"
import delay from "delay"

const MenuItems = Keyframes.Trail({
  open: async next => {
    await delay(300)
    await next({
      opacity: 1,
      transform: "translateY(0px)",
    })
  },
  closed: async next => {
    await next({
      opacity: 0,
      transform: "translateY(20px)",
    })
  },
})

const Menu = ({ isDesktop, isOpen, onClose }) => {
  const menuClass = classNames({
    "navbar-nav flex-grow-1": true,
    "d-none d-lg-flex": isDesktop,
    "d d-lg-none": !isDesktop,
  })

  const items = [
    {
      link: "/",
      text: "Home",
    },
    {
      link: "/diensten",
      text: "Diensten",
    },
    {
      link: "/tarieven",
      text: "Tarieven",
    },
    {
      link: "/offerte",
      text: "Offerte",
    },
    {
      link: "phone",
      text: "phone",
    },
  ]
  return (
    <ul className={menuClass}>
      <MenuItems
        keys={item => item.link}
        items={items}
        state={isOpen ? "open" : "closed"}
        reverse={!isOpen}
        config={config.fast}
      >
        {item => ({ ...trailprops }) => {
          return (
            <>
              {item.link === "phone" && (
                <li className="nav-item ml-lg-auto" style={trailprops}>
                  <a
                    className="nav-link nav-link--blue active"
                    href="tel:0203542540"
                  >
                    Telefoon: 020 354 2540
                  </a>
                </li>
              )}
              {item.link !== "phone" && (
                <li className="nav-item" style={trailprops}>
                  <Link
                    className="nav-link nav-link--orange"
                    onClick={event => {
                      onClose()
                      return false
                    }}
                    to={item.link}
                    activeClassName="active"
                  >
                    {item.text}
                  </Link>
                </li>
              )}
            </>
          )
        }}
      </MenuItems>
    </ul>
  )
}

export default Menu
