/* eslint-disable react/jsx-props-no-spreading */
import React from "react"
import { Link, StaticQuery, graphql } from "gatsby"
import Facebook from "./facebook"
import Instagram from "./instagram"
import Marktplaats from "./marktplaats"
import Google from "./google"
import Whatsapp from "./whatsapp"
import LinkedIn from "./linkedin"
import { category } from "../utils/utils"

const FooterComponent = ({
  data: {
    allMarkdownRemark: { edges },
  },
}) => {
  const Categories = (cat, prefix = "") =>
    edges
      .filter(edge => edge.node.frontmatter.category === cat) // You can filter your posts based on some criteria
      .map(edge => (
        <li className="nav-item" key={edge.node.id}>
          <Link
            className="nav-link nav-link--footer"
            to={`/${category(cat).pathPrefix}/${edge.node.frontmatter.slug}`}
          >
            {prefix}
            {edge.node.frontmatter.title}
          </Link>
        </li>
      ))

  return (
    <footer>
      <section className="bg-light py-6">
        <div className="container-fluid">
          <div className="row navbar-light">
            <div className="col-md-3 mb-md-0 mb-3">
              <ul className="navbar-nav list-unstyled">
                <li className="nav-item mb-1">
                  <a
                    className="nav-link--social"
                    href="https://www.facebook.com/verhuisdeal/"
                  >
                    <Facebook className="mr-1" />
                    Facebook
                  </a>
                </li>
                <li className="nav-item mb-1">
                  <a
                    className="nav-link--social"
                    href="https://www.instagram.com/verhuisdeal24/"
                  >
                    <Instagram className="mr-1" />
                    Instagram
                  </a>
                </li>
                <li className="nav-item mb-1">
                  <a
                    className="nav-link--social"
                    href="https://goo.gl/maps/X1q6T9fHAntHjYwa9"
                  >
                    <Google className="mr-1" />
                    Google
                  </a>
                </li>
                <li className="nav-item mb-1">
                  <a
                    className="nav-link--social"
                    href="https://wa.me/31622441648?text="
                  >
                    <Whatsapp className="mr-1" />
                    Whatsapp
                  </a>
                </li>
                <li className="nav-item mb-1">
                  <a
                    className="nav-link--social"
                    href="https://www.marktplaats.nl/u/verhuisdeal/19117483/"
                  >
                    <Marktplaats className="mr-1" />
                    Marktplaats
                  </a>
                </li>
                <li className="nav-item mb-1">
                  <a
                    className="nav-link--social"
                    href="https://www.linkedin.com/company/verhuisdeal/"
                  >
                    <LinkedIn className="mr-1" />
                    Linkedin
                  </a>
                </li>
              </ul>
            </div>

            <div className="col-md-3 mb-md-0 mb-3">
              <h6 className="h7 font-weight-normal mb-0">Verhuis aanbod</h6>
              <ul className="navbar-nav list-unstyled mb-2">
                {Categories("offer")}
              </ul>
              <h6 className="h7 font-weight-normal mb-0">Diensten</h6>
              <ul className="navbar-nav list-unstyled">
                {Categories("service")}
              </ul>
            </div>

            <div className="col-md-3 mb-md-0 mb-3">
              <h6 className="h7 font-weight-normal mb-0">
                Wij verhuizen onder anderen in:
              </h6>
              <ul className="navbar-nav list-unstyled">
                {Categories("city", "Verhuizen in ")}
              </ul>
            </div>
            <div className="col-md-3 mb-md-0">
              <h6 className="h7 font-weight-normal mb-0">
                Wij verhuizen ook naar:
              </h6>
              <ul className="navbar-nav list-unstyled">
                {Categories("country", "Verhuizen naar ")}
              </ul>
            </div>
          </div>
        </div>
      </section>

      <section className="bg-white py-3">
        <div className="container-fluid">
          <div className="row">
            <div className="col-12">
              <div className="h7 d-flex align-items-sm-center flex-column flex-sm-row">
                <span className="mr-4">Copyright 2021</span>
                <span className="mr-4">Verhuisdeal</span>
                <span className="mr-4">Markengouw 418 1024 EK Amsterdam</span>
                <a className="" href="tel:0203542540">
                  020 354 2540
                </a>
              </div>
            </div>
          </div>
        </div>
      </section>
    </footer>
  )
}

const Footer = props => (
  <StaticQuery
    query={graphql`
      query {
        allMarkdownRemark(sort: { order: DESC, fields: [frontmatter___date] }) {
          edges {
            node {
              id
              excerpt(pruneLength: 250)
              frontmatter {
                date(formatString: "MMMM DD, YYYY")
                slug
                title
                category
              }
            }
          }
        }
      }
    `}
    render={data => <FooterComponent data={data} {...props} />}
  />
)

Footer.propTypes = {}

Footer.defaultProps = {}

export default Footer
