// Icon
import React from "react"

function Icon({ className }) {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      className={className}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M1.7309 0H22.2691C23.2251 0 24 0.774902 24 1.7309V22.2691C24 23.2251 23.2251 24 22.2691 24H15.8465V14.9929H19.3154L20 10.5142H15.8465V7.47867H20V3H15.8465C13.5813 3 11.7386 5.00899 11.7386 7.47867V10.5142H9V14.9929H11.7386V24H1.7309C0.774902 24 0 23.2251 0 22.2691V1.7309C0 0.774902 0.774902 0 1.7309 0Z"
        fill="black"
      />
    </svg>
  )
}

export default Icon
